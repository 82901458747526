export default {
  colors: {
    background: '#ffffff',
    text: '#201B1C',
    primary: '#201B1C',
    secondary: '#E7BFC7',
    tertiary: '#f3f3f3',
    // tertiary: '#e7e7e7',
    neutral: '#FFF1F0',
    accent: '#FFEDF1',
    highlight: '#E7BFC7',
  },
  fonts: {
    header: '"Open Sans", sans-serif',
    // header: '"Montserrat", "Playfair Display", serif',
    body:
      '"Lato", -apple-system, BlinkMacSystemFont, Poppins, Arial, san-serif;',
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  screen: {
    small: '35em',
    medium: '47em',
    large: '70em',
    xlarge: '80em',
  },
}
